import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../reducers';
import { canAccessFeatures } from '../utils/featureFlag';
import { OrganizationType } from '@energybox/react-ui-library/dist/types/Organization';

export enum FeatureFlagTag {
  EDM = 'edm',
}

type OwnProps = {
  children: React.ReactElement;
  orgType?: OrganizationType;
  orgWhiteList?: number[];
  legacy?: boolean;
  tag?: FeatureFlagTag;
};

const hiddenFeatureFlagTags = [FeatureFlagTag.EDM];

export const isHiddenFeatureFlag = (tag: FeatureFlagTag) =>
  hiddenFeatureFlagTags.includes(tag);

interface Props extends OwnProps {
  isVisible: boolean;
}

const FeatureFlag = ({ children, isVisible, legacy, tag }: Props) => {
  if (tag && isHiddenFeatureFlag(tag)) {
    return null;
  }

  if (isVisible === !legacy) {
    return children;
  }

  return null;
};

const mapStateToProps = (
  { app }: ApplicationState,
  { orgWhiteList, orgType }: OwnProps
) => ({
  isVisible: canAccessFeatures(app, orgWhiteList, orgType),
});

export default connect(mapStateToProps)(FeatureFlag);
