import IndexManagementPageLayout from '../../components/IndexManagementPageLayout';
import TabLink from '../../components/TabLink';
import { Routes } from '../../routes';
import history from '../../history';
import EdmView from './Edm/EdmView';
import HvacControls from './HvacControls/HvacControls';
import LightingControls from './LightingEquipmentContorls/LightingControls';
import OtherControls from './OtherEquipmentControls/OtherControls';
import FeatureFlag, { FeatureFlagTag } from '../FeatureFlag';
import { pageNotFoundError } from '../../utils/ApiError/pageNotFoundError';

const OrgControlsManagement = () => {
  const pathName = history?.location?.pathname || '';
  const splitPath = `/${pathName.split('/')[2]}`;

  const checkRoute = (splitPath, targetRoute) => {
    return splitPath === targetRoute;
  };

  const renderComponent = () => {
    switch (true) {
      case checkRoute(splitPath, Routes.EDM):
        return (
          <FeatureFlag tag={FeatureFlagTag.EDM}>
            <EdmView />
          </FeatureFlag>
        );
      case checkRoute(splitPath, Routes.LIGHTING):
        return <LightingControls />;
      case checkRoute(splitPath, Routes.OTHERS):
        return <OtherControls />;
      case checkRoute(splitPath, Routes.HVAC_CONTROLS):
        return <HvacControls />;
      case splitPath === '/':
        history.push(`${Routes.CONTROLS}${Routes.HVAC_CONTROLS}`);
        return null;
      default:
        return pageNotFoundError();
    }
  };
  return (
    <IndexManagementPageLayout
      tabs={
        <>
          <TabLink to={`${Routes.CONTROLS}${Routes.HVAC_CONTROLS}`}>
            HVAC
          </TabLink>
          <TabLink to={`${Routes.CONTROLS}${Routes.LIGHTING}`}>
            Lighting
          </TabLink>
          <TabLink to={`${Routes.CONTROLS}${Routes.OTHERS}`}>Others</TabLink>
          <FeatureFlag tag={FeatureFlagTag.EDM}>
            <TabLink to={`${Routes.CONTROLS}${Routes.EDM}`}>
              DemandManager
            </TabLink>
          </FeatureFlag>
        </>
      }
    >
      {renderComponent()}
    </IndexManagementPageLayout>
  );
};

export default OrgControlsManagement;
