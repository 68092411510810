import React from 'react';
import {
  FilterDropdown,
  FilterDropdownTextItem,
  PageSelectControl,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';
import { getCustomPageRowsNumbers } from '@energybox/react-ui-library/dist/utils/pagination';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EquipmentMenuFilter from '../../components/Filters/EquipmentMenuFilter';
import FiltersContainer from '../../components/Filters/FiltersContainer/FiltersContainer';
import { PageContentHeader, FixedContentWrapper } from '../../components/Page';
import SiteFilter from '../../components/SiteFilter';
import { ApplicationState } from '../../reducers';
import NewOrEditHvacSopModal from './NewOrEditHvacSopModal';
import { SiteHvacData } from '../../reducers/organizations';
import { getOrganizationHvacData } from '../../actions/sops';
import useSiteFilter from '../../hooks/useSiteFilter';
import { useHvacSopDataToEdit } from '../../components/EditHvacSopForm/EditHvacSopForm';
import DeleteHvacSopModal from '../../components/DeleteHvacSopModal';
import { Placeholder } from '../../types/global';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import theme from '../../theme';
import { hasSubstr } from '@energybox/react-ui-library/dist/utils';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import { useUrlState } from '../../hooks/utils';
import styles from '../../components/HvacSopTable/HvacSopTable.module.css';
import { DEFAULT_PAGINATION_ROWS_COUNT } from '../../constants/pagination';
import SiteGroupFilter from '../../components/SiteGroupFilter';
import useSiteGroupsFilter from '../../hooks/useSiteGroupsFilter';
import usePaginationFilter from '../../hooks/usePaginationFilter';
import { getNetworkGroupsByOrgId } from '../../actions/network_groups';
import { handleScrollToTop } from '../../utils/string';
import RenderSopData from './SopData';
import history from '../../history';
import { pageNotFoundError } from '../../utils/ApiError/pageNotFoundError';
import EdmFilter from '../../components/EdmFilter';

import ReportGenerateDropDownButton from '../../components/Button/ReportGenerateButton/ReportGenerateDropDownButton';
import FeatureFlag, { FeatureFlagTag } from '../FeatureFlag';

type Props = {
  scrollIntoViewProp: string;
};

const ShowOrgLevelHVACSopsPage: React.FC<Props> = ({ scrollIntoViewProp }) => {
  const orgId = useCurrentOrganizationId();
  const dispatch = useDispatch();
  const sopToEdit = useHvacSopDataToEdit();
  const { selectedSiteFilters } = useSiteFilter();
  const { siteGroupWithoutSites } = useSiteGroupsFilter();

  const [query, setQuery] = useUrlState<string>('query', '');

  const [equipmentAffectedCount, setEquipmentAffectedCount] = useState(0);
  const urlHvacSopId = Number(history.location.hash.slice(1));
  const [featureHvacSopId, setFeatureHvacSopId] = useState(urlHvacSopId);

  useEffect(() => {
    if (orgId) {
      dispatch(getNetworkGroupsByOrgId(orgId));
      dispatch(getOrganizationHvacData(orgId));
    }
  }, [orgId]);

  useEffect(() => {
    if (urlHvacSopId !== featureHvacSopId) {
      setFeatureHvacSopId(urlHvacSopId);
    }
  }, [urlHvacSopId]);

  const orgData: SiteHvacData[] = useSelector(({ sops }: ApplicationState) => {
    return Object.values(sops.orgHvacData!);
  });

  const { currentPage, rowLimit, setPagination } = usePaginationFilter(
    orgData?.length
  );

  const setCurrentPage = (currentPage, currentRowLimit) => {
    if (currentPage === 1) {
      setPagination(currentPage, DEFAULT_PAGINATION_ROWS_COUNT);
    } else {
      setPagination(currentPage, currentRowLimit);
    }
  };

  const isModalVisible = useSelector<ApplicationState, boolean>(({ sops }) => {
    return sops.showNewOrEditHvacSopModal;
  });
  const [idToDelete, setIdToDelete] = useState<null | number>(null);
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState<boolean>(
    false
  );
  const siteIdToEdit = sopToEdit?.fields?.organizationUnitId;
  const isMobile = ViewportTypes.MOBILE;

  const filteredQuickSearch =
    query && query.length >= 3
      ? orgData.filter(siteData =>
          hasSubstr(`${siteData.title}${siteData.address}`, query)
        )
      : orgData;

  const handleSearchChange = (query: string) => {
    setQuery(query);
  };
  const startIndex = (currentPage - 1) * rowLimit;
  const endIndex = startIndex + rowLimit;
  const filteredBySites = filteredQuickSearch.filter(siteData => {
    if (selectedSiteFilters.length === 0) return true;
    return selectedSiteFilters.includes(siteData.siteId);
  });
  const displayItems = filteredBySites
    .sort(({ title: aTitle }, { title: bTitle }) =>
      aTitle.localeCompare(bTitle)
    )
    .slice(startIndex, endIndex);

  const urlSopIdExist = displayItems.filter(item => {
    return item.sop.find(sop => sop.id === featureHvacSopId);
  });

  const renderError =
    featureHvacSopId > 0 && urlSopIdExist.length < 1 && displayItems.length;

  const pageRows = getCustomPageRowsNumbers(filteredBySites.length);

  const filterDropdownTextItems = pageRows.map(number => (
    <FilterDropdownTextItem
      key={`pageRows${number}`}
      title={number}
      onClick={() => setPagination(1, number)}
      className={styles.rowLimitDropdown}
      closeOnClick
    />
  ));

  return renderError ? (
    pageNotFoundError()
  ) : (
    <div id='HvacsSOPContainer'>
      {isModalVisible && siteIdToEdit && (
        <NewOrEditHvacSopModal
          organizationUnitId={siteIdToEdit.toString()}
          equipmentAffectedCount={equipmentAffectedCount}
          isOrgLevelEdit={true}
        />
      )}
      {isDeleteModalShowing && (
        <DeleteHvacSopModal
          isDeleteModalShowing={isDeleteModalShowing}
          idToDelete={idToDelete}
          setIdToDelete={setIdToDelete}
          setIsDeleteModalShowing={setIsDeleteModalShowing}
          equipmentAffectedCount={equipmentAffectedCount}
          isOrgLevelEdit={true}
        />
      )}
      <FixedContentWrapper>
        <PageContentHeader header={'Management'}>
          <SearchBox
            placeholder={Placeholder.seachBox}
            onChange={handleSearchChange}
            query={query}
            width={
              isMobile
                ? theme.size.table.searchBox.mobile
                : theme.size.table.searchBox.web
            }
            widthActive={
              isMobile
                ? theme.size.table.searchBox.mobile
                : theme.size.table.searchBox.web
            }
            error={filteredQuickSearch?.length === 0}
          />
        </PageContentHeader>
        <FiltersContainer growFirst>
          <FiltersContainer>
            <SiteFilter />
            <SiteGroupFilter />
            <EquipmentMenuFilter label='Equipment Type' isOrglevelHvac={true} />
            <FeatureFlag tag={FeatureFlagTag.EDM}>
              <EdmFilter />
            </FeatureFlag>
          </FiltersContainer>

          <ReportGenerateDropDownButton orgId={orgId} type='HVAC_SOP' />
        </FiltersContainer>

        {siteGroupWithoutSites ? (
          <></>
        ) : (
          <div className={styles.pageSelectWrapper}>
            <div className={styles.cardHeader}>
              Displaying {filteredBySites.length} Sites
            </div>
            <div className={styles.alignPageSelectionEl}>
              <>
                Show Rows
                <div className={styles.rowLimitDropdownContainer}>
                  <FilterDropdown
                    title={rowLimit}
                    variant='select'
                    size='tiny'
                    className={styles.rowLimitDropdown}
                  >
                    {filterDropdownTextItems}
                  </FilterDropdown>
                </div>
              </>
              {filteredBySites.length > 0 && (
                <PageSelectControl
                  hidePageNumbers={true}
                  pageCount={Math.ceil(filteredBySites.length / rowLimit)}
                  currentPage={currentPage}
                  setCurrentPage={currentPage => {
                    setCurrentPage(currentPage, rowLimit);
                  }}
                ></PageSelectControl>
              )}
            </div>
          </div>
        )}

        {!siteGroupWithoutSites && (
          <RenderSopData
            displayItems={displayItems}
            setEquipmentAffectedCount={setEquipmentAffectedCount}
            orgId={orgId!}
            setIdToDelete={setIdToDelete}
            setIsDeleteModalShowing={setIsDeleteModalShowing}
            setFeatureHvacSopId={setFeatureHvacSopId}
            featureHvacSopId={featureHvacSopId}
          />
        )}
        <div className={styles.pagination}>
          {filteredBySites.length > 0 && !siteGroupWithoutSites && (
            <PageSelectControl
              pageCount={Math.ceil(filteredBySites.length / rowLimit)}
              currentPage={currentPage}
              setCurrentPage={currentPage => {
                setCurrentPage(currentPage, rowLimit);
                handleScrollToTop(scrollIntoViewProp);
              }}
            ></PageSelectControl>
          )}
        </div>
      </FixedContentWrapper>
    </div>
  );
};

export default ShowOrgLevelHVACSopsPage;
