import {
  DeviceState,
  Edge,
  edgeDeviceTextEnum,
  IamReportTemplate,
  NetworkGroup,
  OpacityIndex,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  genericTableSort,
  SORT_IGNORED_VALUES,
  hasSubstr,
  isDefined,
  classNames,
} from '@energybox/react-ui-library/dist/utils';
import {
  ExtraShortSkeletonCell,
  MediumSkeletonCell,
  SearchBox,
  ShortMediumSkeletonCell,
  ShortSkeletonCell,
  Table,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import { pathOr, equals } from 'ramda';

import React, { ReactNode, useMemo } from 'react';
import { FaEye as EyeIcon } from 'react-icons/fa';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  edgeConfigStatusUpdate,
  produceEdgeConfig,
  resetEdgeDeviceState,
} from '../../../actions/edge_devices';
import {
  Actions,
  getNetworkGroupBySiteId,
  getNetworkGroupsByOrgId,
} from '../../../actions/network_groups';
import EdgeConfigFileModal from '../../../components/EdgeConfigFileModal';
import { ApplicationState } from '../../../reducers';
import {
  IsConfigAcceptedByNetworkGroupId,
  IsEdgeDeviceLoadingByNetworkGroupId,
  EdgeDeviceResponseTextByNetworkGroupId,
  EdgeConfigUpdateStatusByEdgeId,
} from '../../../reducers/edge_devices';
import { networkGroupsFromSiteId } from '../../../reducers/network_groups';
import { Routes } from '../../../routes';
import { checkCommonPlural } from '../../../util';
import DeviceOnlineState, {
  getOnlineStatusDisplayValue,
} from '../../DeviceStatus/DeviceOnlineState';
import UpdateEdgeConfigConfirmationModal from '../UpdateEdgeConfigConfirmationModal';
import UpdateEdgeConfigButton from './UpdateEdgeConfigButton';
import LastNetworkGroupConfigUpdate from '../../../components/LastNetworkGroupConfigUpdate';
import styles from './NetworkGroupTable.module.css';
import {
  PageContentHeader,
  DynamicContentWrapper,
} from '../../../components/Page';
import { TableWrapper } from '../../../components/ui/Table';
import { getDeviceStatusSortingFn } from '../../../utils/devices';
import { DeviceStatusById } from '../../../reducers/deviceStatus';
import { Placeholder } from '../../../types/global';
import { getUrlStateParams, updateUrlState } from '../../../hooks/utils';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import theme from '../../../theme';
import history from '../../../history';
import {
  RemoteAccess,
  WarningIcon,
} from '@energybox/react-ui-library/dist/icons';
import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import FiltersContainer from '../../../components/Filters/FiltersContainer/FiltersContainer';
import SiteGroupFilter from '../../../components/SiteGroupFilter';
import { filterBySiteGroups } from '../../../utils/siteGroups/siteGroups';
import SiteFilter from '../../../components/SiteFilter';
import useSiteGroupsFilter from '../../../hooks/useSiteGroupsFilter';
import useSiteFilter from '../../../hooks/useSiteFilter';
import usePaginationFilter from '../../../hooks/usePaginationFilter';
import {
  fetchRelease,
  getBalenaStatusByOrgId,
  getBalenaStatusBySiteId,
} from '../../../actions/balena';
import { BalenaStatusById } from '../../../reducers/balena';
import RemoteAccessButton from '../../../components/RemoteAccessButton/RemoteAccessButton';
import useOnlineStatusesFilter from '../../../hooks/useOnlineStatusFilter';
import OnlineStatusFilter, {
  convertOnlineStateToString,
} from '../../../components/OnlineStatusFilter/OnlineStatusFilter';
import CiscoDeviceStatus from '../../../components/CiscoDeviceStatus';
import { getCiscoDeviceStatusesByTenantId } from '../../../actions/cisco_devices';
import { CiscoDeviceStatusByTenantId } from '../../../reducers/cisco_devices';
import ReportGenerateDropDownButton from '../../../components/Button/ReportGenerateButton/ReportGenerateDropDownButton';

interface OwnProps {
  siteId?: number;
  orgId?: number;
  isOrgView?: boolean;
  viewportType?: ViewportTypes;
}

interface Props extends OwnProps {
  getNetworkGroupBySiteId: typeof getNetworkGroupBySiteId;
  getNetworkGroupsByOrgId: typeof getNetworkGroupsByOrgId;
  produceEdgeConfig: typeof produceEdgeConfig;
  edgeConfigStatusUpdate: typeof edgeConfigStatusUpdate;
  getBalenaStatusBySiteId: typeof getBalenaStatusBySiteId;
  getCiscoDeviceStatusesByTenantId: typeof getCiscoDeviceStatusesByTenantId;
  getBalenaStatusByOrgId: typeof getBalenaStatusByOrgId;
  fetchRelease: typeof fetchRelease;
  setSiteGroupsFilter: (siteGroups: string[]) => void;
  resetEdgeDeviceState: () => void;
  setPagination?: (
    page: number | undefined,
    rowLimit: number | undefined
  ) => void;
  currentPage: number | undefined;
  rowLimit: number | undefined;
  siteNetworkGroups: NetworkGroup[];
  orgNetworkGroups: NetworkGroup[];
  getNetworkGroupsIsLoading?: boolean;
  isEdgeDeviceLoadingByNetworkGroupId: IsEdgeDeviceLoadingByNetworkGroupId;
  isConfigAcceptedByNetworkGroupId: IsConfigAcceptedByNetworkGroupId;
  edgeConfigUpdateStatusByEdgeId: EdgeConfigUpdateStatusByEdgeId;
  edgeDeviceResponseTextByNetworkGroupId: EdgeDeviceResponseTextByNetworkGroupId;
  deviceStatusById: DeviceStatusById;
  selectedSiteFilters: string[];
  siteGroupWithoutSites: boolean;
  selectedOnlineStatusFilters: string[];
  selectedSiteGroups: string[];
  statusByUuid: BalenaStatusById;
  latestSuperHubRelease: string;
  ciscoDeviceStatusByTenantId: CiscoDeviceStatusByTenantId;
}

interface State {
  selectedSerialNumber: string;
  displayConfirmModal: boolean;
  primedNetworkGroupId: number;
  query: string;
  orgId?: number;
  data: NetworkGroup[];
  dataHasSuperHub: boolean;
  dataHas809: boolean;
  pageNumber: number;
}

type NetworkGroupWithEdge = NetworkGroup & {
  edge: Edge;
};

const withDynamicFilter = WrappedComponent => {
  return props => {
    const dataLength = useMemo(() => props.networkGroups?.length, [
      props?.networkGroups?.length,
    ]);

    const { currentPage, rowLimit, setPagination } = usePaginationFilter(
      dataLength
    );

    const {
      selectedSiteGroups,
      setSiteGroupsFilter,
      siteGroupWithoutSites,
    } = useSiteGroupsFilter();
    const { selectedSiteFilters } = useSiteFilter();
    const {
      selectedOnlineStatuses: selectedOnlineStatusFilters,
    } = useOnlineStatusesFilter();

    return (
      <WrappedComponent
        setPagination={setPagination}
        rowLimit={rowLimit}
        currentPage={currentPage}
        selectedSiteGroups={selectedSiteGroups}
        siteGroupWithoutSites={siteGroupWithoutSites}
        selectedSiteFilters={selectedSiteFilters}
        setSiteGroupsFilter={setSiteGroupsFilter}
        selectedOnlineStatusFilters={selectedOnlineStatusFilters}
        {...props}
      />
    );
  };
};

class NetworkGroupTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSerialNumber: '',
      displayConfirmModal: false,
      primedNetworkGroupId: -1,
      query: '',
      data: [],
      dataHasSuperHub: false,
      dataHas809: false,
      pageNumber: 1,
    };
  }

  componentDidMount() {
    const {
      getNetworkGroupBySiteId,
      siteId,
      orgId,
      isOrgView,
      getNetworkGroupsByOrgId,
      getBalenaStatusBySiteId,
      getCiscoDeviceStatusesByTenantId,
      getBalenaStatusByOrgId,
      fetchRelease,
    } = this.props;
    const savedQuery = getUrlStateParams<string>(history, 'query', '');
    const savedPage = getUrlStateParams<number>(history, 'page', 1);

    this.setState({ query: savedQuery });
    this.setState({ pageNumber: savedPage });

    fetchRelease();

    if (typeof siteId === 'number') {
      getNetworkGroupBySiteId(siteId.toString());
      getBalenaStatusBySiteId(siteId);
    }

    if (orgId && isOrgView) {
      getNetworkGroupsByOrgId(orgId);
      getBalenaStatusByOrgId(orgId);
    }

    if (orgId) {
      const fetchCiscoDevices = () => {
        getCiscoDeviceStatusesByTenantId(orgId);
      };

      fetchCiscoDevices(); // Initial fetch
      //Set up periodic token fetch every 2 minutes
      const interval = setInterval(fetchCiscoDevices, 2 * 60 * 1000);

      // // Cleanup on component unmount
      return () => clearInterval(interval);
    }
  }

  componentWillUnmount() {
    const { resetEdgeDeviceState } = this.props;

    resetEdgeDeviceState();
  }

  static getDerivedStateFromProps(nextProps: Props, prevState) {
    const { orgId, orgNetworkGroups, siteNetworkGroups } = nextProps;
    const nextNetworkGroup =
      orgId && orgNetworkGroups ? orgNetworkGroups : siteNetworkGroups;
    let newState: Partial<State> = { data: nextNetworkGroup };
    if (!equals(nextNetworkGroup, prevState.data)) {
      const dataHasSuperHub = nextNetworkGroup?.some(
        networkGroups => networkGroups.edge?.edgeDevice === 'EB_SUPER_HUB'
      );
      newState.dataHasSuperHub = dataHasSuperHub;
      const dataHas809 = nextNetworkGroup?.some(
        networkGroups => networkGroups.edge?.edgeDevice === 'IR809'
      );
      newState.dataHas809 = dataHas809;
    }
    return newState;
  }

  handleSearchChange = (value: string) => {
    this.setState({ query: value }, () => {
      const { query: searchFilter } = this.state;
      updateUrlState(history, 'query', searchFilter);
    });
  };

  pagination = (pageCount: number) => {
    this.setState({ pageNumber: pageCount }, () => {
      const { pageNumber: pageFilter } = this.state;
      updateUrlState(history, 'page', pageFilter);
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      displayConfirmModal: false,
      primedNetworkGroupId: -1,
    });
  };

  openConfirmationModal = (networkGroupId: string | number) => {
    this.setState({
      displayConfirmModal: true,
      primedNetworkGroupId: Number(networkGroupId),
    });
  };

  onConfirmEdgeConfigUpdate = (
    networkGroupId: number | string,
    edgeUuid: string | undefined,
    edgeId: number | undefined
  ) => {
    const { produceEdgeConfig } = this.props;

    produceEdgeConfig(networkGroupId, edgeUuid, edgeId);
    this.closeConfirmationModal();
  };

  resetSelectedSerialNumber = () => {
    this.setState({
      selectedSerialNumber: '',
    });
  };

  onViewConfigFileClick = (serialNumber?: string) => {
    if (!serialNumber) return;
    this.setState({
      selectedSerialNumber: serialNumber,
    });
  };

  doesEdgeExistInsideNetworkGroup = (
    networkGroup: NetworkGroup
  ): networkGroup is NetworkGroupWithEdge => {
    return !!networkGroup.edge;
  };

  doesEdgeSerialNumberExistInsideNetworkGroup = (
    networkGroup: NetworkGroup
  ) => {
    return (
      this.doesEdgeExistInsideNetworkGroup(networkGroup) &&
      !!networkGroup.edge.serialNumber
    );
  };

  getSuperHubSerialNumberFromNetworkGroup = (
    networkGroup: NetworkGroup
  ): string | false | undefined => {
    // false implies superhub is not yet assigned a serial#
    return this.doesEdgeExistInsideNetworkGroup(networkGroup) &&
      networkGroup.edge.edgeDevice === 'EB_SUPER_HUB'
      ? networkGroup.edge.serialNumber || false
      : undefined;
  };

  getCISCOSerialNumberFromNetworkGroup = (
    networkGroup: NetworkGroup
  ): string | false | undefined => {
    return this.doesEdgeExistInsideNetworkGroup(networkGroup) &&
      networkGroup.edge.edgeDevice === 'IR809'
      ? networkGroup.edge.serialNumber || false
      : undefined;
  };

  filterBySites = (data, sites) => {
    return data?.filter(site => sites.includes(site.siteId));
  };

  getOnlineStatus = (
    deviceStatusById: DeviceStatusById,
    networkGroup: NetworkGroup
  ) => {
    let onlineStatusValue;
    if (networkGroup.edge?.id) {
      const id = networkGroup.edge?.id;
      let deviceStatuses: DeviceState[] = [];
      if (deviceStatusById[id]) deviceStatuses = [deviceStatusById[id]];
      onlineStatusValue = getOnlineStatusDisplayValue(deviceStatuses)
        .displayValue;
    }
    return convertOnlineStateToString(onlineStatusValue);
  };

  render() {
    const {
      siteNetworkGroups,
      orgNetworkGroups,
      orgId,
      deviceStatusById,
      edgeConfigUpdateStatusByEdgeId,
      viewportType,
      selectedSiteGroups,
      selectedSiteFilters,
      selectedOnlineStatusFilters,
      currentPage,
      isOrgView,
      setPagination,
      rowLimit,
      siteId,
      statusByUuid,
      latestSuperHubRelease,
    } = this.props;

    const {
      selectedSerialNumber,
      displayConfirmModal,
      primedNetworkGroupId,
      query,
      dataHasSuperHub,
      dataHas809,
    } = this.state;

    const hasSiteFilter = !isDefined(siteId);

    const isMobile = viewportType === ViewportTypes.MOBILE;
    const networkGroups =
      orgId && isOrgView && orgNetworkGroups
        ? orgNetworkGroups
        : siteNetworkGroups;
    const queryNetworkGroups =
      query && query.length >= 3
        ? networkGroups?.filter(networkGroup =>
            hasSubstr(`${networkGroup.title}`, query)
          )
        : networkGroups;

    const availableOnlineStatuses: string[] = [];
    networkGroups.forEach(item => {
      const onlineStatus = this.getOnlineStatus(deviceStatusById, item);

      if (!availableOnlineStatuses.includes(onlineStatus))
        availableOnlineStatuses.push(onlineStatus);
    });

    let filteredData = queryNetworkGroups;

    if (selectedSiteGroups?.length) {
      filteredData = filterBySiteGroups(filteredData, selectedSiteFilters);
    } else if (selectedSiteFilters?.length) {
      filteredData = this.filterBySites(filteredData, selectedSiteFilters);
    }

    if (selectedOnlineStatusFilters.length) {
      filteredData = filteredData.filter(item =>
        selectedOnlineStatusFilters.includes(
          this.getOnlineStatus(deviceStatusById, item)
        )
      );
    }

    const getVersionValue = networkGroup => {
      const regex = /-rc/;
      const serialNumber = networkGroup?.edge?.serialNumber || '';
      const raw_version =
        statusByUuid[serialNumber]?.runningRelease?.[0]?.raw_version || '';
      const runningVersion = regex.test(raw_version)
        ? raw_version
        : raw_version.split('-')[0];
      const appVersion = networkGroup?.edge?.edgeStatus?.appVersion;
      const isSuperHub = networkGroup?.edge?.edgeDevice === 'EB_SUPER_HUB';
      return isSuperHub
        ? runningVersion
          ? runningVersion
          : null
        : appVersion
        ? appVersion
        : null;
    };

    const columns: Columns<NetworkGroup>[] = [
      {
        header: 'Network Group',
        cellContent: networkGroup => (
          <Link
            to={`${Routes.DEVICES}${Routes.NETWORK_GROUPS}/${networkGroup.id}`}
          >
            {networkGroup.title}
          </Link>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortMediumSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: NetworkGroup,
          b: NetworkGroup,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      ...(orgId
        ? [
            {
              header: 'Site',
              cellContent: networkGroup => (
                <Link
                  to={`${Routes.SITES}/${networkGroup.siteId}${Routes.NETWORK_GROUPS}`}
                  target='_blank'
                >
                  {networkGroup.site?.title}
                </Link>
              ),
              skeletonCellContent: (rowIndex: OpacityIndex) => (
                <ShortMediumSkeletonCell opacityIndex={rowIndex} />
              ),
              comparator: (
                a: NetworkGroup,
                b: NetworkGroup,
                sortDirection: SortDirection
              ) => {
                return genericTableSort(
                  a,
                  b,
                  sortDirection,
                  SORT_IGNORED_VALUES,
                  ['site', 'title']
                );
              },
              showSortIcons: true,
            },
          ]
        : []),
      {
        header: 'Edge Control Device',
        cellContent: networkGroup =>
          networkGroup.edge && (
            <span>
              {networkGroup.edge.edgeDevice
                ? edgeDeviceTextEnum[networkGroup.edge.edgeDevice]
                : global.NOT_AVAILABLE}
            </span>
          ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: NetworkGroup,
          b: NetworkGroup,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'edge',
            'edgeDevice',
          ]);
        },
      },
      {
        header: 'Status / Last Check-In',
        cellContent: networkGroup =>
          (networkGroup.edge && networkGroup.edge.uuid && (
            <DeviceOnlineState
              devices={[
                {
                  id: networkGroup.edge.id,
                  uuid: networkGroup.edge.uuid,
                  vendor: 'energybox',
                },
              ]}
              offlineReason={networkGroup.edge.onlineStatus?.offlineReason}
              // Only OnlineStatus from iam gives offlineReason thus only the
              // first retrieval of offlineReason (possibly null) will be
              // displayed, but OnlineState from stream can still decide
              // whether offlineReason is being displayed, if the reason was
              // not null

              // offlineReason is SuperHub-only value, will not work on Cisco
              // offlineReason can only be set correcly only if -
              // 1. The SuperHub device has enough battery after unplugged
              // 2. The SuperHub has network connectivity
              // So do not rely on this value for anything
            />
          )) ||
          global.NOT_AVAILABLE,
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortMediumSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: getDeviceStatusSortingFn(deviceStatusById, true),
      },
      {
        header: 'UUID',
        cellContent: networkGroup =>
          networkGroup.edge && (
            <span>{networkGroup.edge.uuid || global.NOT_AVAILABLE}</span>
          ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: NetworkGroup,
          b: NetworkGroup,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'edge',
            'uuid',
          ]);
        },
      },
      {
        header: 'Serial Number',
        cellContent: networkGroup =>
          networkGroup.edge && (
            <span className={styles.wrapFirst}>
              {networkGroup.edge.serialNumber || global.NOT_AVAILABLE}
            </span>
          ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortMediumSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: NetworkGroup,
          b: NetworkGroup,
          sortDirection: SortDirection
        ) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'edge',
            'serialNumber',
          ]);
        },
      },
      {
        header: 'Version',
        cellContent: networkGroup => {
          const version = getVersionValue(networkGroup);
          const isSuperHub = networkGroup?.edge?.edgeDevice === 'EB_SUPER_HUB';
          return (
            <span>
              {!version ? (
                global.NOT_AVAILABLE
              ) : isSuperHub ? (
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '2px' }}
                >
                  <b>{'Build: '}</b>
                  {version}
                  {latestSuperHubRelease && version !== latestSuperHubRelease && (
                    <Tooltip
                      content={<div>Not the latest release version</div>}
                      arrowDirection='top'
                      underline={false}
                    >
                      <div style={{ paddingTop: '2px' }}>
                        <WarningIcon size={14} />
                      </div>
                    </Tooltip>
                  )}
                </div>
              ) : (
                <div>
                  <b>{'Edge App: '}</b>
                  {version}
                </div>
              )}
            </span>
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortMediumSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a, b, sortDirection) => {
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            getVersionValue
          );
        },
      },
      {
        header: 'Last Config Update',
        cellContent: networkGroup => {
          if (!networkGroup.edge?.id) {
            return <div>{global.NOT_AVAILABLE}</div>;
          }
          return <LastNetworkGroupConfigUpdate networkGroup={networkGroup} />;
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortMediumSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (
          a: NetworkGroup,
          b: NetworkGroup,
          sortDirection: SortDirection
        ) => {
          const getHashEqualData = networkGroup => {
            const hasEdgeData =
              edgeConfigUpdateStatusByEdgeId[networkGroup?.edge?.id!];
            if (hasEdgeData?.lastSentHashTimeStamp)
              return hasEdgeData?.isHashEqual;
          };
          const getTimeStamp = networkGroup => {
            return edgeConfigUpdateStatusByEdgeId[networkGroup?.edge?.id!]
              .lastSentHashTimeStamp;
          };
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            getHashEqualData,
            getTimeStamp,
            ['title']
          );
        },
      },
      {
        header: 'Action',
        cellContent: networkGroup => {
          const updateEdgeConfigButton = (
            <UpdateEdgeConfigButton
              networkGroup={networkGroup}
              openConfirmationModal={this.openConfirmationModal}
            />
          );
          const doesEdgeSerialNumberExist = this.doesEdgeSerialNumberExistInsideNetworkGroup(
            networkGroup
          );
          return (
            <div className={styles.actionColumn}>
              {updateEdgeConfigButton}
              {doesEdgeSerialNumberExist ? (
                <span
                  className={styles.icon}
                  onClick={() => {
                    if (networkGroup.edge === null) return;
                    this.onViewConfigFileClick(networkGroup.edge.serialNumber);
                  }}
                >
                  <EyeIcon size={25} color={'var(--ambient-basePlus25)'} />
                </span>
              ) : (
                <div style={{ minWidth: '25px' }} />
              )}
            </div>
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
      },
      ...(dataHasSuperHub || dataHas809
        ? [
            {
              header: 'Remote Access',
              cellContent: networkGroup => {
                const serialNumber = this.getSuperHubSerialNumberFromNetworkGroup(
                  networkGroup
                );
                const isSuperhub = isDefined(serialNumber);
                const ciscoSerialNumber = this.getCISCOSerialNumberFromNetworkGroup(
                  networkGroup
                );
                if (
                  serialNumber === undefined &&
                  ciscoSerialNumber === undefined
                )
                  return null;
                if (serialNumber === false && ciscoSerialNumber === false) {
                  // when superhub is not yet assigned a serial#, grey out the button
                  return (
                    <div className={styles.remoteAccessDisabled}>
                      <RemoteAccess size={25} tint='currentColor' />
                    </div>
                  );
                } else {
                  return isSuperhub && serialNumber ? (
                    <RemoteAccessButton
                      serialNumber={serialNumber}
                      balenaStatus={statusByUuid[serialNumber] || undefined}
                      className={styles.remoteAccessButtonContainer}
                    />
                  ) : (
                    <CiscoDeviceStatus
                      serialNumber={ciscoSerialNumber}
                      className={styles.remoteAccessButtonContainer}
                    />
                  );
                }
              },
              skeletonCellContent: (rowIndex: OpacityIndex) => (
                <ExtraShortSkeletonCell opacityIndex={rowIndex} />
              ),
            },
          ]
        : []),
    ];

    return (
      <DynamicContentWrapper>
        <PageContentHeader header='All Network Groups'>
          <SearchBox
            placeholder={Placeholder.seachBox}
            onChange={this.handleSearchChange}
            query={query}
            width={
              isMobile
                ? theme.size.table.searchBox.mobile
                : theme.size.table.searchBox.web
            }
            widthActive={
              isMobile
                ? theme.size.table.searchBox.mobile
                : theme.size.table.searchBox.web
            }
            error={filteredData?.length === 0}
          />
          <ReportGenerateDropDownButton
            type={IamReportTemplate.IAM_NETWORK_GROUP}
            orgId={orgId}
            siteId={siteId}
          />
        </PageContentHeader>
        <FiltersContainer>
          {hasSiteFilter && <SiteFilter />}
          {hasSiteFilter && <SiteGroupFilter />}
          <OnlineStatusFilter availableOptions={availableOnlineStatuses} />
        </FiltersContainer>
        <TableWrapper
          header={checkCommonPlural(
            'Network Group',
            filteredData && filteredData?.length
          )}
          pageNavHidden={filteredData?.length == 0 ? true : false}
        >
          <Table
            listView
            columns={columns}
            data={filteredData}
            rowLimitFromPaginationHook={rowLimit}
            currentPageFromPaginationHook={currentPage}
            setPagination={setPagination}
          />
        </TableWrapper>

        {selectedSerialNumber !== '' && (
          <EdgeConfigFileModal
            onCancel={this.resetSelectedSerialNumber}
            serialNumber={selectedSerialNumber}
          />
        )}

        {displayConfirmModal && (
          <UpdateEdgeConfigConfirmationModal
            networkGroupId={primedNetworkGroupId}
            onConfirmEdgeConfigUpdate={this.onConfirmEdgeConfigUpdate}
            closeConfirmationModal={this.closeConfirmationModal}
          />
        )}
      </DynamicContentWrapper>
    );
  }
}
// TODO: need to update networkGroupIdsBySiteId on delete so this doesn't crash, for now temp solution is
// to only have items that exists hence the filter
const mapStateToProps = (
  {
    networkGroups,
    edgeDevices,
    deviceStatusById,
    balena,
    ciscoDevices,
  }: ApplicationState,
  { siteId, orgId }: OwnProps
) => ({
  siteNetworkGroups: networkGroupsFromSiteId(
    networkGroups,
    String(siteId)
  ).filter(n => n),
  orgNetworkGroups: networkGroups?.networkGroupsByOrgId[orgId ?? ''],
  getNetworkGroupsIsLoading: pathOr(
    undefined,
    [Actions.GET_NETWORK_GROUP_BY_SITE_ID_LOADING, siteId],
    networkGroups?.loadingStatusByAction
  ),
  isEdgeDeviceLoadingByNetworkGroupId:
    edgeDevices?.isEdgeDeviceLoadingByNetworkGroupId,
  isConfigAcceptedByNetworkGroupId:
    edgeDevices?.isConfigAcceptedByNetworkGroupId,
  edgeDeviceResponseTextByNetworkGroupId:
    edgeDevices?.edgeDeviceResponseTextByNetworkGroupId,
  deviceStatusById: deviceStatusById,
  edgeConfigUpdateStatusByEdgeId: edgeDevices?.edgeConfigUpdateStatusByEdgeId,
  statusByUuid: balena.statusByUuid,
  latestSuperHubRelease: balena.firmwareReleases[0]?.raw_version,
  ciscoDevicesByTenantId: ciscoDevices?.ciscoDevicesByTenantId,
});

const mapDispatchToProps = {
  getNetworkGroupBySiteId,
  produceEdgeConfig,
  resetEdgeDeviceState,
  edgeConfigStatusUpdate,
  getNetworkGroupsByOrgId,
  getBalenaStatusBySiteId,
  getBalenaStatusByOrgId,
  getCiscoDeviceStatusesByTenantId,
  fetchRelease,
};

export default withDynamicFilter(
  connect(mapStateToProps, mapDispatchToProps)(NetworkGroupTable)
);
