import {
  IamReportTemplate,
  OpacityIndex,
  ResourceType,
  Role,
  SortDirection,
  User,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  SORT_IGNORED_VALUES,
  hasSubstr,
  values,
} from '@energybox/react-ui-library/dist/utils';
import {
  MediumLongSkeletonCell,
  ShortMediumSkeletonCell,
  Button,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Actions, getUsers, showNewUserModal } from '../../actions/users';
import AccessResourceRecord from '../../components/AccessResourceRecord';
import UsersManagementLayout from '../../components/UsersManagementLayout';
import { ApplicationState } from '../../reducers';
import { Routes } from '../../routes';
import { CreateNewText } from '../../types/global';
import { checkCommonPlural, fullName } from '../../util';
import NewUserModal from './NewUserModal';
import history from '../../history';
import { TableWrapper } from '../../components/ui/Table';
import { Placeholder } from '../../types/global';

import theme from '../../theme';
import {
  PageContentHeader,
  DynamicContentWrapper,
} from '../../components/Page';
import withViewportType from '@energybox/react-ui-library/dist/hoc/withViewportType';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import { getUrlStateParams, updateUrlState } from '../../hooks/utils';
import NewInstallerModal from './NewInstallerModal';
import { UserStatus } from '@energybox/react-ui-library/dist/types/User';
import styles from './ShowUserPage.module.css';
import ReportGenerateDropDownButton from '../../components/Button/ReportGenerateButton/ReportGenerateDropDownButton';

interface Props {
  getUsers: typeof getUsers;
  showNewUserModal: typeof showNewUserModal;
  users: User[];
  getUsersIsLoading: boolean | undefined;
  viewportType: ViewportTypes;
  showInstallers?: boolean;
}

interface State {
  query: string;
  usersLoadingGuard: boolean;
  pageNumber: number;
}

const SORT_BY_USERSTATUS: UserStatus[][] = [
  ['ACTIVE', 'TIME_LOCKED'],
  ['INVITED'],
  ['PROSPECT', null],
  ['INVITATION_EXPIRED'],
  ['SUSPENDED'],
];

const getUserStatusMessage = ({
  lastLogin,
  userStatus,
}: {
  lastLogin?: string;
  userStatus: UserStatus;
}): JSX.Element => {
  switch (userStatus) {
    case null:
    case 'PROSPECT':
      return <span className={styles.prospect}>Prospect</span>;
    case 'ACTIVE':
    case 'TIME_LOCKED':
      return (
        <span title={lastLogin || undefined}>
          {lastLogin
            ? `${formatDistance(parseISO(lastLogin), new Date())} ago`
            : 'Never logged in'}
        </span>
      );
    case 'INVITATION_EXPIRED':
      return <span className={styles.alert}>Invitation Expired</span>;
    case 'INVITED':
      return <span>Invited</span>;
    case 'SUSPENDED':
      return <span className={styles.alert}>Account Suspended</span>;
  }
};

class UsersPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      query: '',
      usersLoadingGuard: true,
      pageNumber: 1,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ usersLoadingGuard: false });
    }, 750);

    const savedQuery = getUrlStateParams<string>(history, 'query', '');
    const savedPage = getUrlStateParams<number>(history, 'page', 1);

    this.setState({ query: savedQuery });
    this.setState({ pageNumber: savedPage });

    this.props.getUsers();
  }

  handleSearchChange = (value: string) => {
    this.setState({ query: value }, () => {
      const { query: searchFilter } = this.state;
      updateUrlState(history, 'query', searchFilter);
    });
  };

  pagination = (pageCount: number) => {
    this.setState({ pageNumber: pageCount }, () => {
      const { pageNumber: pageFilter } = this.state;
      updateUrlState(history, 'page', pageFilter);
    });
  };

  render() {
    const {
      users,
      getUsersIsLoading,
      viewportType,
      showInstallers,
    } = this.props;
    const isMobile = viewportType === ViewportTypes.MOBILE;
    const { query, usersLoadingGuard } = this.state;
    const isLoading = getUsersIsLoading || usersLoadingGuard;

    const actions = (
      <>
        <Button onClick={this.props.showNewUserModal}>
          {showInstallers ? CreateNewText.INSTALLER : CreateNewText.USER}
        </Button>
      </>
    );

    const filteredUsers =
      query && query.length >= 3
        ? users.filter((user: User) =>
            hasSubstr(`${user.firstName} ${user.lastName}`, query)
          )
        : users;

    const columns: Columns<User>[] = [
      {
        width: '20%',
        header: `${showInstallers ? 'Installer' : 'User'} Name`,
        cellContent: (user: User) => (
          <Link to={`${Routes.USERS}/${user.id}`}>{fullName(user)}</Link>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortMediumSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: User, b: User, sortDirection: SortDirection) => {
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            fullName
          );
        },
      },
      {
        width: '15%',
        header: 'Position / Work Place',
        cellContent: ({ position, organizationUnitTitle }: User) => (
          <>
            <b>{position || '-'}</b>
            <div>{organizationUnitTitle || '-'}</div>
          </>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortMediumSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: User, b: User, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'position',
          ]);
        },
      },
      showInstallers
        ? {
            header: 'Access to Organizations',
            width: '20%',
            cellContent: ({ accessResources }: User) => (
              <>
                {(accessResources || [])
                  .filter(
                    resource =>
                      resource.resourceType === ResourceType.ORGANIZATION &&
                      resource.role === Role.INSTALLER
                  )
                  .map(resource => (
                    <AccessResourceRecord key={resource.id} {...resource} />
                  ))}
              </>
            ),
            skeletonCellContent: (rowIndex: OpacityIndex) => (
              <MediumLongSkeletonCell opacityIndex={rowIndex} />
            ),
          }
        : {
            header: 'Site Access',
            width: '20%',
            cellContent: ({ accessResources }: User) => (
              <>
                {(accessResources || []).map(resource => (
                  <AccessResourceRecord key={resource.id} {...resource} />
                ))}
              </>
            ),
            skeletonCellContent: (rowIndex: OpacityIndex) => (
              <MediumLongSkeletonCell opacityIndex={rowIndex} />
            ),
          },
      {
        width: '20%',
        header: 'Email',
        cellContent: ({ email }: User) => (
          <a href={`mailto:${email}`}>{email}</a>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: User, b: User, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'email',
          ]);
        },
      },
      ...(showInstallers
        ? []
        : [
            {
              width: '10%',
              header: 'Phone',
              cellContent: ({ phone }: User) => (
                <a href={`tel:${phone}`}>{phone}</a>
              ),
              skeletonCellContent: (rowIndex: OpacityIndex) => (
                <MediumLongSkeletonCell opacityIndex={rowIndex} />
              ),
              comparator: (a: User, b: User, sortDirection: SortDirection) => {
                return genericTableSort(
                  a,
                  b,
                  sortDirection,
                  SORT_IGNORED_VALUES,
                  ['phone']
                );
              },
            },
          ]),
      {
        width: '15%',
        header: 'Status / Last Login',
        cellContent: user => getUserStatusMessage(user),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortMediumSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: User, b: User, sortDirection: SortDirection) => {
          const aStatusIdx = SORT_BY_USERSTATUS.findIndex(statuses =>
            statuses.includes(a.userStatus)
          );
          const bStatusIdx = SORT_BY_USERSTATUS.findIndex(statuses =>
            statuses.includes(b.userStatus)
          );

          let ascending;
          if (
            a.userStatus === 'SUSPENDED' ||
            b.userStatus === 'SUSPENDED' ||
            a.userStatus === 'INVITATION_EXPIRED' ||
            b.userStatus === 'INVITATION_EXPIRED' ||
            a.userStatus === 'INVITED' ||
            b.userStatus === 'INVITED'
          ) {
            ascending = aStatusIdx - bStatusIdx;
          } else {
            ascending = genericTableSort(
              a,
              b,
              SortDirection.DESC,
              SORT_IGNORED_VALUES,
              ['lastLogin']
            );
            if (ascending === 0) {
              ascending = bStatusIdx - aStatusIdx;
            }
          }
          return sortDirection === SortDirection.ASC ? ascending : -ascending;
        },
      },
    ];

    return (
      <UsersManagementLayout actions={actions} showInstallers={showInstallers}>
        <DynamicContentWrapper>
          <PageContentHeader>
            <SearchBox
              placeholder={Placeholder.seachBox}
              onChange={this.handleSearchChange}
              query={query}
              width={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              widthActive={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              error={filteredUsers.length === 0}
            />
            <ReportGenerateDropDownButton type={IamReportTemplate.IAM_USERS} />
          </PageContentHeader>
          <TableWrapper
            header={checkCommonPlural(
              showInstallers ? 'Installer' : 'User',
              filteredUsers.length
            )}
            isLoading={isLoading}
          >
            <Table
              listView
              columns={columns}
              data={!getUsersIsLoading ? filteredUsers : []}
              dataIsLoading={isLoading}
              pagination={this.pagination}
              pageNumber={filteredUsers.length > 5 ? this.state.pageNumber : 1}
            />
          </TableWrapper>
        </DynamicContentWrapper>

        {showInstallers ? <NewInstallerModal /> : <NewUserModal />}
      </UsersManagementLayout>
    );
  }
}

const mapStateToProps = ({ users }: ApplicationState) => ({
  users: values(users.usersById),
  getUsersIsLoading: users.loadingStatusByAction[Actions.GET_USERS_LOADING],
});

const mapDispatchToProps = {
  getUsers,
  showNewUserModal,
};

export default withViewportType(
  connect(mapStateToProps, mapDispatchToProps)(UsersPage)
);
