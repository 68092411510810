import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getResourcePathsByIds } from '../../actions/paths';
import { ApplicationState } from '../../reducers';
import { PathsById, IsPathLoadingById } from '../../reducers/paths';
import { ResourceType } from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';

type OwnProps = {
  resourceId: number;
  resourceType: ResourceType;
  suppressLink?: boolean;
};

type StateProps = {
  resourcePathsById: PathsById;
  resourcePathsIsLoadingById: IsPathLoadingById;
};

type DispatchProps = {
  getResourcePathsByIds: typeof getResourcePathsByIds;
};

type Props = OwnProps & StateProps & DispatchProps;

const ResourcePaths: React.FC<Props> = ({
  resourceId,
  resourceType,
  resourcePathsById,
  resourcePathsIsLoadingById,
  getResourcePathsByIds,
  suppressLink,
}) => {
  const path = resourcePathsById[resourceId] || [];
  const isLoading = resourcePathsIsLoadingById[resourceId];

  React.useEffect(() => {
    if (!path.length && !isLoading) {
      getResourcePathsByIds([resourceId]);
    }
  }, [resourceId, path, isLoading, getResourcePathsByIds]);

  if (isLoading) {
    return <>Loading...</>;
  }
  console.debug('path', path);

  if (path.length) {
    const [site, ...rest] = path;
    return (
      <div>
        <div>
          {suppressLink ? (
            <strong>{site.title}</strong>
          ) : (
            <Link to={`/sites/${site.id}`}>
              <strong>{site.title}</strong>
            </Link>
          )}
        </div>
        {rest
          .filter(p => p.type === resourceType)
          .map(r => r.title)
          .join(' > ')}
      </div>
    );
  }

  return <>{global.NOT_AVAILABLE}</>;
};

const mapStateToProps = ({ resourcePaths }: ApplicationState): StateProps => ({
  resourcePathsById: resourcePaths.byIds,
  resourcePathsIsLoadingById: resourcePaths.isLoadingById,
});

const mapDispatchToProps: DispatchProps = {
  getResourcePathsByIds,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourcePaths);
