import {
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';

import { useState } from 'react';
import ConfirmReportDialog from '../../../containers/ShowEquipmentPage/ConfirmReportDialog';

import {
  globalReport,
  toggleShowHideIcon,
  notifyDownload,
  generateIamReport,
} from '../../../actions/reportworker';
import ReportGenerateButton from './ReportGenerateButton';
import { useDispatch } from 'react-redux';
import { IamReportTemplate } from '@energybox/react-ui-library/dist/types';

interface Props {
  type: IamReportTemplate | 'HVAC_SOP';
  siteId?: number;
  orgId?: number;
}

const ReportGenerateDropDownButton = ({ orgId, siteId, type }: Props) => {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSelect = () => {
    if (type === 'HVAC_SOP') {
      const params = {
        reportTemplate: type,
        organizationId: orgId!,
        periodType: '',
        reportFromDate: '',
      };
      dispatch(globalReport(params));
    } else if (type === IamReportTemplate.IAM_NETWORK_GROUP) {
      const params = {
        reportTemplate: type,
        organizationId: !!siteId ? undefined : orgId!,
        siteId: siteId!,
      };
      dispatch(generateIamReport(params));
    } else {
      const iamParams = {
        reportTemplate: type,
        siteId: siteId,
      };
      dispatch(generateIamReport(iamParams));
    }
    setDialogOpen(true);
    dispatch(toggleShowHideIcon(true));
    dispatch(notifyDownload(true));
  };

  return (
    <>
      <MenuDropdown
        disabled={false}
        icon={<ReportGenerateButton disabled={false} />}
      >
        <MenuDropdownItem onSelect={() => handleSelect()}>
          {getButtonText(type)}
        </MenuDropdownItem>
      </MenuDropdown>

      <ConfirmReportDialog
        isCreateDashboardDialogOpen={dialogOpen}
        onSubmit={() => setDialogOpen(false)}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

const getButtonText = (type: IamReportTemplate | 'HVAC_SOP') => {
  switch (type) {
    case IamReportTemplate.IAM_USERS:
      return 'Download User List';
    case IamReportTemplate.IAM_EQUIPMENT:
      return 'Download Equipment List';
    case IamReportTemplate.IAM_SENSOR:
      return 'Download Sensor List';
    case IamReportTemplate.IAM_SITES:
      return 'Download Site List';
    case IamReportTemplate.IAM_DEVICES:
      return 'Download Device List';
    case IamReportTemplate.IAM_NETWORK_GROUP:
      return 'Download Network Group List';
    case 'HVAC_SOP':
      return 'Generate HVAC SOP';
    default:
      return 'Generate Report';
  }
};

export default ReportGenerateDropDownButton;
