import {
  Button,
  Modal,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import { ChevronLeft } from '@energybox/react-ui-library/dist/icons';
import {
  Equipment,
  ReportType,
  SensorType,
} from '@energybox/react-ui-library/dist/types';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEquipmentBySiteIdAndSensorType } from '../../../actions/equipmentFetch';
import {
  Actions as ReportActions,
  create,
  displayFormErrors,
  getReportById,
  hideNewReportModal,
  patch,
  updateField,
} from '../../../actions/reports';
import { ApplicationState } from '../../../reducers';
import { EditText } from '../../../types/global';
import { renderAPIerror } from '../../../utils/apiErrorFeedback';
import ReportContent from '../ReportContent';
import styles from './ReportsModal.module.css';

import { useAnalyticsReDo } from '../../../hooks/report/useAnalytics';
import {
  ReportType as AnalyticsReportType,
  TempCheckInterval,
} from '../../../types/analytics';
import { sub } from 'date-fns';

type Props = {
  siteId: string;
  predeterminedStepNumber?: number;
  selectedReportId?: number;
  resetEditReportModal: () => void;
};

const ReportsModal: React.FC<Props> = ({
  siteId,
  predeterminedStepNumber,
  selectedReportId,
  resetEditReportModal,
}) => {
  const reportId = selectedReportId ? String(selectedReportId) : 'new';
  const dispatch = useDispatch();
  const getReport = useCallback((id: number) => dispatch(getReportById(id)), [
    dispatch,
  ]);

  const closeModal = useCallback(() => dispatch(hideNewReportModal()), [
    dispatch,
  ]);

  const updateReportField = useCallback(
    (field: string, value: any) =>
      dispatch(updateField(reportId, field, value)),
    [dispatch, reportId]
  );

  const { trigger, isLoading } = useAnalyticsReDo(
    siteId,
    AnalyticsReportType.TEMPERATURE_CHECK,
    TempCheckInterval.MONTHLY
  );

  const createReport = useCallback(
    () =>
      dispatch(
        create({
          callback: res => {
            if (res.error) return;
            trigger(sub(new Date(), { days: 60 }), new Date());
          },
        })
      ),
    [dispatch]
  );

  const updateReport = useCallback(
    () =>
      dispatch(
        patch(reportId, {
          callback: res => {
            if (res.error) return;
            trigger(sub(new Date(), { days: 60 }), new Date());
          },
        })
      ),
    [dispatch, reportId, trigger]
  );

  const showFormErrors = useCallback(
    () => dispatch(displayFormErrors(reportId)),
    [dispatch, reportId]
  );

  useEffect(() => {
    if (selectedReportId) {
      getReport(selectedReportId);
    }
  }, [selectedReportId, getReport]);

  useEffect(() => {
    if (!selectedReportId) {
      updateReportField('organizationUnitId', Number(siteId));
    }
  }, [updateReportField, siteId, selectedReportId]);

  useEffect(() => {
    fetchEquipmentBySiteIdAndSensorType(siteId, [SensorType.TEMPERATURE])
      .then(res => setEquipmentList(res))
      .catch(error => console.log(error));
  }, [siteId]);

  const [stepNumber, setStepNumber] = useState(predeterminedStepNumber || 0);
  const [equipmentList, setEquipmentList] = useState<Equipment[]>([]);
  const editReport = useSelector(({ reports }: ApplicationState) => {
    return reports.editReportById[reportId];
  });

  if (!editReport) return null;

  const { fields, apiError, formErrors, formErrorsVisible } = editReport;
  const { resourceIds, periods, reportType } = fields;

  const onNextStep = () => {
    if (stepNumber === 1) {
      if (formErrors.title || formErrors.periods) {
        showFormErrors();
        return;
      }
    }
    setStepNumber(stepNumber + 1);
  };

  const onPreviousStep = () => {
    setStepNumber(stepNumber - 1);
  };

  const onReportModalCancel = () => {
    resetEditReportModal();
    closeModal();
  };

  const isStepValid = () => {
    switch (stepNumber) {
      case 0:
        return reportType !== undefined;

      case 1:
        return periods.length > 0;

      case 2:
        return resourceIds.length > 0;

      case 3:
        return editReport.isChanged;

      default:
        return true;
    }
  };

  const onReportTypeClick = (reportType: ReportType) => {
    updateReportField('reportType', reportType);
    onNextStep();
  };

  const renderActionButtonText = () => {
    if (stepNumber !== 3) {
      return 'Next';
    } else {
      if (reportId === 'new') {
        return 'Finish';
      }
      return 'Update';
    }
  };

  const determineActionButtonFunction = () => {
    if (stepNumber !== 3) {
      return onNextStep;
    } else {
      if (reportId === 'new') {
        return createReport;
      }
      return updateReport;
    }
  };

  const actions = (
    <>
      {stepNumber !== 0 ? (
        <div>
          <Button variant="text" onClick={onPreviousStep}>
            <span className={styles.backButton}>
              <ChevronLeft size={16} />
              <span className={styles.backButtonText}>Back</span>
            </span>
          </Button>
        </div>
      ) : (
        <div />
      )}
      <div>
        <Button variant="text" onClick={onReportModalCancel}>
          Cancel
        </Button>
        <Button
          disabled={!isStepValid()}
          onClick={determineActionButtonFunction()}
        >
          {renderActionButtonText()}
        </Button>
      </div>
    </>
  );

  return (
    <Modal
      className={styles.modal}
      onClose={onReportModalCancel}
      actions={actions}
      actionsClassName={styles.actionsContainer}
      disableEscapeClose={true}
    >
      <ModalTitle className={styles.modalTitle}>{EditText.REPORT}</ModalTitle>

      <ReportContent
        stepNumber={stepNumber}
        fields={fields}
        equipmentList={equipmentList}
        updateReportField={updateReportField}
        onReportTypeClick={onReportTypeClick}
        formErrors={formErrors}
        formErrorsVisible={formErrorsVisible}
      />

      {renderAPIerror(
        apiError,
        ReportActions.CREATE_REPORT_ERROR,
        ReportActions.PATCH_REPORT_ERROR
      )}
    </Modal>
  );
};

export default ReportsModal;
