import { Cell } from '@energybox/react-ui-library/dist/components/CardList';
import {
  ChevronDown,
  ChevronRight,
  Scheduler as SchedulerIcon,
} from '@energybox/react-ui-library/dist/icons';
import {
  Equipment,
  EquipmentType,
} from '@energybox/react-ui-library/dist/types';
import {
  HvacSopTimeSlot,
  Sop,
} from '@energybox/react-ui-library/dist/types/Sop';
import {
  classNames,
  isDefined,
  mapArrayToObject,
} from '@energybox/react-ui-library/dist/utils';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useAppLocale,
  useCurrentUser,
  useIs12HrTimeFormat,
} from '../../hooks/useAppDetails';
import { useCurrentOrganizationId } from '../../hooks/useCurrentOrganization';
import { useEquipmentTypesById } from '../../hooks/useEquipmentTypes';
import { global } from '@energybox/react-ui-library/dist/utils';
import EquipmentCellData from '../../components/HvacSopTable/EquipmentCellData';
import {
  renderFanMode,
  renderlocalAdjustment,
  renderScheduleTimeAndDay,
  renderScheduleType,
  renderSetPoint,
  renderWorkingMode,
  sopColumnWidths,
} from '../../components/HvacSopTable/HvacSopTable';
import FeatureFlag, { FeatureFlagTag } from '../FeatureFlag';
import {
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';
import {
  customizeOrganizationSop,
  showNewOrEditHvacSopModal,
} from '../../actions/sops';
import styles from '../../components/HvacSopTable/HvacSopTable.module.css';
import { hasDaResults } from '../../util';
import RecordInfoTooltip from '../../components/RecordBubble/RecordInfoTooltip';
import { ApplicationState } from '../../reducers';
import TimetableVisualization from '../OrgControlsManagement/TimetableVisualization/TimetableVisualization';
interface Props {
  siteId: number;
  sop: Sop;
  timeSlot: HvacSopTimeSlot;
  index: number;
  isInactiveSop: boolean;
  enableEdm: boolean;
  enableLocalAdjustment: boolean;
  setPointLimitDelta: number | null | undefined;
  overrideTimer: number | null | undefined;
  equipmentsInOrgUnit: Equipment[];
  equipmentTypesInOrgUnit: EquipmentType[];
  setEquipmentAffectedCount?;
  setFeatureHvacSopId?;
  setIdToDelete?;
  setIsDeleteModalShowing?;
}

const SopDataRow: React.FC<Props> = ({
  siteId,
  sop,
  timeSlot,
  index,
  isInactiveSop,
  enableEdm,
  enableLocalAdjustment,
  setPointLimitDelta,
  overrideTimer,
  setEquipmentAffectedCount,
  setFeatureHvacSopId,
  setIdToDelete,
  setIsDeleteModalShowing,
  equipmentsInOrgUnit,
  equipmentTypesInOrgUnit,
}) => {
  const { equipmentTypeIds, title, description } = sop;
  const isUnoccupied = timeSlot.hvacScheduleType === 'UNOCCUPIED';
  const [isExpanded, setIsExpanded] = useState(isUnoccupied ? false : true);
  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();
  const user = useCurrentUser();
  const is12HrFormat = useIs12HrTimeFormat();
  const hvacSchedules = sop?.components[0]['hvacSettings']['hvacSchedules'];
  const [
    openedTimetableVisualizations,
    setOpenedTimetableVisualizations,
  ] = useState(new Array<Array<JSX.Element | undefined>>(hvacSchedules.length));

  const siteEquipmentTypesById = () => {
    if (equipmentTypesInOrgUnit && equipmentTypesInOrgUnit.length > 0) {
      return mapArrayToObject(
        equipmentTypesInOrgUnit.filter(equipment => isDefined(equipment))
      );
    } else return '';
  };

  //To display the test-inprogress-warning.
  const currentSite = useSelector(
    ({ sites }: ApplicationState) => sites.sitesById[siteId]
  );
  const daResultsExist: boolean = hasDaResults(
    currentSite?.installerTestResults
  );

  const locale = useAppLocale();
  const allAvailableTypeIds = useEquipmentTypesById();
  const equipmentTypesInSop = allAvailableTypeIds[equipmentTypeIds[0]];
  const displayTypes = siteEquipmentTypesById();
  const equipmentType =
    isDefined(displayTypes) &&
    isDefined(equipmentTypeIds[0]) &&
    isDefined(displayTypes[equipmentTypeIds[0]])
      ? displayTypes[equipmentTypeIds[0]]
      : equipmentTypesInSop;
  const equipmentsMatchingSite = isDefined(equipmentType)
    ? Object.values(equipmentsInOrgUnit).filter(
        equipment => equipment.typeId === equipmentType.id
      )
    : [];
  const isOrgLevelSop = isDefined(orgId) && orgId === sop.organizationUnitId;
  const expandIcon = isExpanded ? (
    <ChevronDown size={13} color={'#00A1AF'} />
  ) : (
    <ChevronRight size={13} color={'#00A1AF'} />
  );
  const isFirstRow = index === 0;

  const renderRecordInfoTooltip = () => {
    return (
      <RecordInfoTooltip
        resourceId={sop.id}
        ianaTimeZoneCode={currentSite?.timeZone}
      />
    );
  };

  return (
    <>
      {index !== 0 && <Cell width='3' />}
      <Cell width='2' className={classNames(styles.cardInfoText)}>
        {isDefined(equipmentType) && index === 0 && equipmentType.title}
        {!isDefined(equipmentType) && index === 0 && global.NOT_AVAILABLE}
      </Cell>
      <Cell width='2'>
        <EquipmentCellData
          equipmentsMatchingSite={equipmentsMatchingSite}
          isInactiveSop={isInactiveSop}
          index={index}
        ></EquipmentCellData>
      </Cell>
      <>
        {index !== 0 ? null : (
          <Cell width='2'>
            <div className={styles.cellColumn}>
              <span className={styles.sopDescription}>
                {isOrgLevelSop ? 'Organization' : 'Site'}
              </span>
              <br />
              <span className={styles.sopTitleOrgLevel}>{title}</span>
              <span className={styles.sopDescription}>{description}</span>
            </div>
          </Cell>
        )}
        <Cell className={styles.cardInfoText} width='2'>
          {index !== 0
            ? null
            : renderlocalAdjustment(
                enableLocalAdjustment,
                setPointLimitDelta,
                overrideTimer
              )}
        </Cell>
        <FeatureFlag tag={FeatureFlagTag.EDM}>
          <Cell className={styles.cardInfoText} width='1'>
            {index !== 0 ? null : enableEdm ? 'On' : 'Off'}
          </Cell>
        </FeatureFlag>
        <Cell
          className={classNames(
            styles.timeslotCell,
            isUnoccupied ? '' : styles.occupiedTimeslotCell,
            isFirstRow ? styles.occupiedTimeslotCellFirstRow : ''
          )}
          width='2'
        >
          <div
            onClick={() => isUnoccupied && setIsExpanded(!isExpanded)}
            className={classNames(
              styles.scheduleTypeCell,
              isUnoccupied ? styles.clickableCell : ''
            )}
          >
            {isUnoccupied && expandIcon}
            {renderScheduleType(timeSlot, index)}
          </div>
        </Cell>
        <Cell
          className={classNames(
            styles.timeslotCell,
            isUnoccupied ? '' : styles.occupiedTimeslotCell,
            isFirstRow ? styles.occupiedTimeslotCellFirstRow : ''
          )}
          width='2'
        >
          {isExpanded && renderScheduleTimeAndDay(timeSlot, is12HrFormat)}
        </Cell>
        <Cell
          className={classNames(
            styles.timeslotCell,
            styles.gridColumnLeftSpace,
            isUnoccupied ? '' : styles.occupiedTimeslotCell,
            isFirstRow ? styles.occupiedTimeslotCellFirstRow : ''
          )}
          width='2'
        >
          {renderWorkingMode(timeSlot, index)}
        </Cell>
        <Cell
          className={classNames(
            styles.timeslotCell,
            styles.gridColumnLeftSpace,
            isUnoccupied ? '' : styles.occupiedTimeslotCell,
            isFirstRow ? styles.occupiedTimeslotCellFirstRow : ''
          )}
          width='2'
        >
          {renderSetPoint(timeSlot, index, user, locale)}
        </Cell>
        <Cell
          className={classNames(
            styles.timeslotCell,
            styles.gridColumnLeftSpace,
            isUnoccupied ? '' : styles.occupiedTimeslotCell,
            isFirstRow ? styles.occupiedTimeslotCellFirstRow : ''
          )}
          width='1'
        >
          {renderFanMode(timeSlot, index)}
        </Cell>
        <Cell width='1' className={styles.moreDetails}>
          {index === 0 && (
            <>
              <MenuDropdown disabled={daResultsExist ? true : false}>
                {isOrgLevelSop ? (
                  <MenuDropdownItem
                    onSelect={() => {
                      dispatch(
                        customizeOrganizationSop(
                          { ...sop, organizationUnitId: siteId },
                          'hvac'
                        )
                      );
                    }}
                  >
                    Customize
                  </MenuDropdownItem>
                ) : (
                  <>
                    <MenuDropdownItem
                      onSelect={() => {
                        setEquipmentAffectedCount(
                          equipmentsMatchingSite?.length || 0
                        );
                        setFeatureHvacSopId(-1);
                        dispatch(showNewOrEditHvacSopModal(sop.id, siteId));
                      }}
                    >
                      Edit
                    </MenuDropdownItem>
                    <MenuDropdownItem
                      onSelect={() => {
                        setIdToDelete(sop.id);
                        setEquipmentAffectedCount(
                          equipmentsMatchingSite?.length || 0
                        );
                        setFeatureHvacSopId(-1);

                        setIsDeleteModalShowing(true);
                      }}
                    >
                      <span className={styles.deleteButtonText}>Delete</span>
                    </MenuDropdownItem>
                  </>
                )}
              </MenuDropdown>
            </>
          )}
        </Cell>
      </>
      {index === hvacSchedules.length - 1 && (
        <Cell
          width={sopColumnWidths.fullWidth}
          className={styles.iconsContainer}
        >
          {renderRecordInfoTooltip()}
          <div
            onClick={() =>
              handleSchedulerButtonClick(
                openedTimetableVisualizations,
                index,
                hvacSchedules,
                setOpenedTimetableVisualizations
              )
            }
            className={styles.schedulerIcon}
          >
            <SchedulerIcon size={30} />
          </div>
        </Cell>
      )}

      {
        <Cell
          width={sopColumnWidths.fullWidth}
          className={styles.timetableContainer}
        >
          {openedTimetableVisualizations[index] &&
            openedTimetableVisualizations[index].map((component, i) => {
              return (
                <div key={i} className={styles.rowDropdownContent}>
                  {component}
                </div>
              );
            })}
        </Cell>
      }
    </>
  );
};

export default SopDataRow;

export const handleSchedulerButtonClick = (
  openedTimetableVisualizations: (JSX.Element | undefined)[][],
  index: number,
  hvacSchedules,
  setOpenedTimetableVisualizations: React.Dispatch<
    React.SetStateAction<(JSX.Element | undefined)[][]>
  >
) => {
  let openedVisualizationsCopy = [...openedTimetableVisualizations];
  [...Array(openedTimetableVisualizations.length).keys()].forEach(i => {
    if (i === index) {
      if (
        openedVisualizationsCopy[i] === undefined ||
        openedVisualizationsCopy[i].length === 0
      ) {
        openedVisualizationsCopy[i] = [
          <TimetableVisualization
            hvacSchedules={hvacSchedules}
            numberOfColumns={Number(sopColumnWidths.fullWidth)}
            className={styles.timeTableWidth}
          />,
        ];
      } else {
        openedVisualizationsCopy[i] = [];
      }
    }
  });
  setOpenedTimetableVisualizations(openedVisualizationsCopy);
};
