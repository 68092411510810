import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Routes } from '../../routes';
import { ApplicationState } from '../../reducers';
import SitesManagementLayout from '../../components/SitesManagementLayout';
import NewSiteModal from './NewSiteModal';
import SopInfo from '../Sop/SopInfo';

import {
  getSites,
  showNewSiteModal,
  Actions,
  getSitesOnlineStatus,
} from '../../actions/sites';
import { getTimeTables } from '../../actions/time_tables';
import { SitesById, SitesOnlineStatusById } from '../../reducers/sites';
import { getTimeZones } from '../../actions/app';

import { Columns as TableColumn } from '@energybox/react-ui-library/dist/components/Table';
import {
  Button,
  SearchBox,
  Table,
  ShortSkeletonCell,
  MediumLongSkeletonCell,
  LongExtraLongSkeletonCell,
} from '@energybox/react-ui-library/dist/components';
import {
  Site,
  OpacityIndex,
  SortDirection,
  SiteOnlineStats,
  IamReportTemplate,
} from '@energybox/react-ui-library/dist/types';
import {
  hasSubstr,
  values,
  global,
  SORT_IGNORED_VALUES,
  genericTableSort,
  getTimezoneAbbreviation,
} from '@energybox/react-ui-library/dist/utils';
import history from '../../history';
import { checkCommonPlural, filterDataBySiteId } from '../../util';
import { CreateNewText, Placeholder } from '../../types/global';
import theme from '../../theme';
import {
  PageContentHeader,
  DynamicContentWrapper,
} from '../../components/Page';
import { TableWrapper } from '../../components/ui/Table';
import withViewportType from '@energybox/react-ui-library/dist/hoc/withViewportType';
import { ViewportTypes } from '@energybox/react-ui-library/dist/hooks';
import { getUrlStateParams, updateUrlState } from '../../hooks/utils';
import { determineShouldHideFromInstaller } from '../../utils/user';
import usePaginationFilter from '../../hooks/usePaginationFilter';
import { getSopComponents } from '../../actions/sops';
import { SopComponentsByResourceId } from '../../reducers/sop';
import { SiteDeploymentStatusLabels } from '@energybox/react-ui-library/dist/types/Site';
import ReportGenerateDropDownButton from '../../components/Button/ReportGenerateButton/ReportGenerateDropDownButton';

interface Props {
  orgId?: number;
  getSites: typeof getSites;
  getTimeZones: typeof getTimeZones;
  getTimeTables: typeof getTimeTables;
  showNewSiteModal: typeof showNewSiteModal;
  getSitesOnlineStatus: typeof getSitesOnlineStatus;
  getSopComponents: typeof getSopComponents;
  setPagination?: (
    page: number | undefined,
    rowLimit: number | undefined
  ) => void;
  currentPage: number | undefined;
  rowLimit: number | undefined;
  sites: Site[];
  sitesById: SitesById;
  getSitesIsLoading: boolean | undefined;
  status: SiteOnlineStats;
  hideFromInstaller: boolean;
  viewportType: ViewportTypes;
  loadingStatus: boolean;
  siteSopData: SopComponentsByResourceId;
}

interface State {
  query: string;
  sitesLoadingGuard: boolean;
}

const withDynamicFilter = WrappedComponent => {
  return props => {
    const dataLength = useMemo(() => props.sites?.length, [
      props?.sites?.length,
    ]);

    const { currentPage, rowLimit, setPagination } = usePaginationFilter(
      dataLength
    );

    return (
      <WrappedComponent
        setPagination={setPagination}
        rowLimit={rowLimit}
        currentPage={currentPage}
        {...props}
      />
    );
  };
};

class SitesPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      query: '',
      sitesLoadingGuard: true,
    };
  }

  componentDidMount() {
    const {
      orgId,
      getTimeZones,
      getSites,
      getTimeTables,
      getSitesOnlineStatus,
      getSopComponents,
    } = this.props;
    setTimeout(() => {
      this.setState({ sitesLoadingGuard: false });
    }, 750);

    const savedQuery = getUrlStateParams<string>(history, 'query', '');

    this.setState({ query: savedQuery });

    getTimeZones();
    getSites();
    orgId && getTimeTables(orgId);
    getSitesOnlineStatus();
    getSopComponents('SITE');
  }

  handleSearchChange = (value: string) => {
    this.setState({ query: value }, () => {
      const { query: searchFilter } = this.state;
      updateUrlState(history, 'query', searchFilter);
    });
  };

  render() {
    const {
      sites,
      getSitesIsLoading,
      viewportType,
      hideFromInstaller,
      currentPage,
      setPagination,
      rowLimit,
      status,
      loadingStatus,
      siteSopData,
    } = this.props;
    const isMobile = viewportType === ViewportTypes.MOBILE;
    const { query, sitesLoadingGuard } = this.state;
    const isLoading = getSitesIsLoading || sitesLoadingGuard;

    // button
    const actions = (
      <>
        {!hideFromInstaller && (
          <Button onClick={this.props.showNewSiteModal}>
            {CreateNewText.SITE}
          </Button>
        )}
      </>
    );

    const filteredSites =
      query && query.length >= 3
        ? sites.filter((site: Site) =>
            hasSubstr(`${site.title} ${site.address}`, query)
          )
        : sites;

    const columns: TableColumn<Site>[] = [
      {
        width: '20%',
        header: 'Site Name',
        cellContent: (site: Site) => (
          <Link to={`${Routes.SITES}/${site.id}/general`}>{site.title}</Link>
        ),
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Site, b: Site, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        width: '25%',
        header: 'Address',
        cellContent: (site: Site) => site.address,
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Site, b: Site, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'address',
          ]);
        },
      },
      {
        width: '10%',
        header: 'Time Zone',
        cellContent: (site: Site) =>
          site.timeZone ? getTimezoneAbbreviation(site.timeZone) : '',
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Site, b: Site, sortDirection: SortDirection) => {
          const getSiteTimezoneAbbrev = (site: Site) => {
            return site.timeZone ? getTimezoneAbbreviation(site.timeZone) : '';
          };
          return genericTableSort(
            a,
            b,
            sortDirection,
            SORT_IGNORED_VALUES,
            getSiteTimezoneAbbrev,
            ['title']
          );
        },
      },
      {
        width: '20%',
        header: 'SOP Policies',
        cellContent: (site: Site) => {
          const sopData = filterDataBySiteId(siteSopData, site.id);
          return (
            <SopInfo
              resourceId={String(site.id)}
              sopTypeCategory={'policy'}
              isEquipment={false}
              sopData={sopData}
            />
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
      },
      {
        width: '15%',
        header: 'SOP Cost',
        cellContent: (site: Site) => {
          const sopData = filterDataBySiteId(siteSopData, site.id);
          return (
            <SopInfo
              resourceId={String(site.id)}
              sopTypeCategory={'cost'}
              isEquipment={false}
              sopData={sopData}
            />
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
      },
      {
        width: '10%',
        header: 'Deployment Status',
        cellContent: (site: Site) => {
          return (
            SiteDeploymentStatusLabels[site.siteInstallationStatus] ||
            global.NOT_AVAILABLE
          );
        },
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <ShortSkeletonCell opacityIndex={rowIndex} />
        ),
        comparator: (a: Site, b: Site, sortDirection: SortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'siteInstallationStatus',
          ]);
        },
        defaultSortDirection: SortDirection.DESC,
      },
    ];
    return (
      <SitesManagementLayout
        actions={actions}
        showSiteGroups={!hideFromInstaller}
      >
        <DynamicContentWrapper>
          <PageContentHeader>
            <SearchBox
              placeholder={Placeholder.seachBox}
              onChange={this.handleSearchChange}
              query={query}
              width={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              widthActive={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              error={filteredSites.length === 0}
            />
            <ReportGenerateDropDownButton type={IamReportTemplate.IAM_SITES} />
          </PageContentHeader>

          <TableWrapper
            isLoading={isLoading}
            header={checkCommonPlural('Site', filteredSites.length)}
          >
            <Table
              listView
              columns={columns}
              data={filteredSites || []}
              dataIsLoading={isLoading}
              uniqueKeyField='id'
              rowLimitFromPaginationHook={rowLimit}
              currentPageFromPaginationHook={currentPage}
              setPagination={setPagination}
            />
          </TableWrapper>
        </DynamicContentWrapper>
        <NewSiteModal />
      </SitesManagementLayout>
    );
  }
}

const mapStateToProps = ({
  sites,
  app,
  organizations,
  sops,
}: ApplicationState) => ({
  orgId: app.currentOrganizationId,
  sitesById: sites.sitesById,
  sites: values(sites.sitesById),
  getSitesIsLoading: sites.loadingStatusByAction[Actions.GET_SITES_LOADING],
  hideFromInstaller: determineShouldHideFromInstaller(
    app.currentUser,
    organizations.currentOrganization
  ),
  status: sites.status,
  loadingStatus: sites.loadingStatus,
  siteSopData: sops.sopComponentData,
});

const mapDispatchToProps = {
  getSites,
  getTimeZones,
  showNewSiteModal,
  getTimeTables,
  getSitesOnlineStatus,
  getSopComponents,
};

export default withViewportType(
  withDynamicFilter(connect(mapStateToProps, mapDispatchToProps)(SitesPage))
);
